import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import Layout from "../components/Layout"
import BlogItem from "../components/BlogItem"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import Navbar from "../components/Navbar"
import { shuffle } from "lodash"
import SEO from "../components/seo"
import axios from "axios"
import ListItem from "../components/ListItem"
import PostItem from "../components/PostItem"

const DirectoryCard = ({ title, description, slug }) => (
  <Link
    to={`/collection/${slug}`}
    css={css`
      text-decoration: none;
    `}
  >
    <div
      css={css`
        margin-bottom: 30px;
      `}
    >
      <h2
        css={css`
          margin: 0;
          color: #0178bd;
        `}
      >
        {title}
      </h2>
      <p
        css={css`
          color: #6b6b6b;
          margin: 10px 0;
        `}
      >
        {description}
      </p>
    </div>
  </Link>
)
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}
export default function Home({ data }) {
  const {
    allAirtable: { edges },
  } = data

  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)

  const onSubscribe = () => {
    if (email) {
      axios
        .post("https://slashblogs.com/subscribe", {
          email,
          tag: "weeklydigest",
        })
        .then(function (res) {
          setEmail("")
          console.log(res)
          setSubscribed(true)
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }

  return (
    <Layout>
      <SEO title="Home" />

      <div
        css={css`
          margin-bottom: 80px;
        `}
      >
        <div
          css={css`
            text-align: center;
            margin-bottom: 20px;
            margin-top: 40px;
          `}
        >
          <h1
            css={css`
              margin-bottom: 0;
            `}
          >
            Latest Blog Posts
          </h1>
          {/* <h3
            css={css`
              margin-top: 5px;
              margin-bottom: 10px;
            `}
          >
            {currentDate}
          </h3>
          {!subscribed && (
            <span>
              Get the best blog posts every week
              <input
                css={css`
                  margin-left: 10px;
                  padding: 5px 10px;
                  font-size: 14px;
                  border-radius: 5px;
                  border: 1px solid #bababa;
                `}
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="blogsurfer@gmail.com"
              ></input>
              <button
                css={css`
                  background-color: #0178bd;
                  color: #fff;
                  border: none;
                  border-radius: 5px;
                  padding: 6px;
                  margin-left: 3px;
                  font-size: 12px;

                  cursor: pointer;
                `}
                onClick={onSubscribe}
              >
                Subscribe
              </button>
            </span>
          )}
          {subscribed && (
            <div
              css={css`
                margin-top: 12px;
                font-size: 16px;
                color: green;
              `}
            >
              Successfully subscribed!
            </div>
          )} */}
        </div>
        <div
          css={css`
            margin: 0 auto;
            max-width: 800px;
          `}
        >
          {edges.slice(0, 25).map((edge, i) => {
            const {
              node: { data },
            } = edge
            return <PostItem data={data} index={i} />
          })}
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;

              a {
                margin: 0 20px;
                font-size: 18px;
              }
            `}
          >
            <Link to="/posts/2">Next</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostsQuery {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { order: DESC, fields: data___Last_Post_Timestamp }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
